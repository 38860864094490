import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import VoiceCard from "./voiceCard";

const VoiceCards = ({ onSelect, text, apiKey, voice, voiceId }) => {
  const [selectedVoice, setSelectedVoice] = useState(voice);

  // List of voices (can remain the same)
  const voices = [
    {
      name: "Lily",
      id: "pFZP5JQG7iQjIQuC4Bku",
      languageCode: "all",
      model: "eleven_turbo_v2_5",
      provider: "elevenlabs",
      lowLatency: false,
      accent: "british",
      image:
        "https://images.unsplash.com/photo-1634149134165-6d679d80a800?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
    {
      name: "Vikram",
      id: "7Q6qcYvsTRgb4IVcoAdK",
      languageCode: "all",
      model: "eleven_turbo_v2_5",
      provider: "elevenlabs",
      lowLatency: false,
      accent: "indian",
      image:
        "https://plus.unsplash.com/premium_photo-1682089877310-b2308b0dc719?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
    {
      name: "Wendy",
      id: "pHrhhjXcvwG4jZEKtXm0",
      languageCode: "all",
      model: "eleven_turbo_v2_5",
      provider: "elevenlabs",
      lowLatency: false,
      accent: "american",
      image:
        "https://images.unsplash.com/photo-1573496359142-b8d87734a5a2?q=80&w=1976&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
    {
      name: "Sheps Rocky",
      id: "d5xU2Rwln0n15oHMmaTU",
      languageCode: "all",
      model: "eleven_turbo_v2_5",
      provider: "elevenlabs",
      lowLatency: false,
      accent: "american",
      image:
        "https://images.unsplash.com/photo-1557862921-37829c790f19?q=80&w=2071&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
  ];

  // Handle voice selection
  const handleSelectVoice = (voiceId) => {
    const selected = voices.find((voice) => voice.id === voiceId);

    if (selected) {
      setSelectedVoice(selected);

      onSelect(selected);
    }
  };

  // Place the selected voice at the top
  const sortedVoices = selectedVoice
    ? [
        selectedVoice,
        ...voices.filter((voice) => voice.id !== selectedVoice.id),
      ]
    : voices;

  // Sync the selected voice when the voiceId changes
  useEffect(() => {
    const foundVoice = voices.find((v) => v.id === voiceId);
    if (foundVoice) {
      setSelectedVoice(foundVoice);
    }
  }, [voiceId]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="flex-start"
      width="100%"
    >
      {sortedVoices.map((val, index) => (
        <VoiceCard
          key={index}
          {...val}
          apiKey={apiKey}
          text={text}
          isSelected={selectedVoice?.id === val.id} // Compare IDs for selection
          onSelect={() => handleSelectVoice(val.id)}
        />
      ))}
    </Box>
  );
};

export default VoiceCards;
