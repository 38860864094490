import React, { useState } from "react";
import {
  Button,
  Card,
  CardContent,
  Typography,
  Container,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  InputLabel,
} from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const tiers = [
  {
    name: "Basic",
    price: 9.99,
    phoneNumbers: 1,
    freeMinutes: 100,
    addOnRate: "10c per minute",
    features: [
      "Enjoy 100 free inbound minutes every month",
      "Easily add more minutes anytime at just 10c per minute",
    ],
  },
  {
    name: "Advanced",
    price: 19.99,
    phoneNumbers: 2,
    freeMinutes: 250,
    addOnRate: "10c per minute",
    features: [
      "Benefit from 250 free inbound minutes every month",
      "Add extra minutes on-demand at 10c per minute with one click",
    ],
  },
  {
    name: "Premium",
    price: 39.99,
    phoneNumbers: 4,
    freeMinutes: 600,
    addOnRate: "8c per minute",
    features: [
      "Take advantage of 600 free inbound minutes monthly",
      "Top up additional minutes easily at just 8c per minute",
    ],
  },
];

const TierSelection = ({ onSelect, userId, agentId, apiKey, user_data }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [testNumber, setTestNumber] = useState("");
  const navigate = useNavigate();
  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleTestNumberChange = (event) => {
    setTestNumber(event.target.value);
  };

  const handleDialogSubmit = async () => {
    const body = {
      agent_id: agentId,
      recipient_phone_number: testNumber,
      from_phone_number: "",
    };
    const response = await axios.post(
      `${process.env.REACT_APP_DASHBOARD_BACKEND}/call`,
      body,
      {
        headers: {
          Authorization: `Bearer ${apiKey}`,
        },
      }
    );
    console.log(response.data);
    console.log(`Test number entered: ${testNumber}`);
    handleClose();
  };
  const currentIndex = tiers.findIndex(
    (tier) => tier.name === user_data.plan_name
  );
  return (
    <Container>
      {/* Test Agent Button */}
      {!user_data.plan_name ? (
        <div style={{              marginTop:"50px",marginBottom:"-80px"        }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleClickOpen}
            size="small"
            sx={{
              position: "static",
              // top: "20px",
              // left: "20px",
              padding: "8px 16px",
              fontSize: "14px",
              fontWeight: "bold",
              borderRadius: "40px",
              boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
              background:
                "linear-gradient(90deg, rgba(33,150,243,1) 0%, rgba(30,136,229,1) 100%)",
              transition: "transform 0.3s ease",
              "&:hover": {
                transform: "scale(1.05)",
                background:
                  "linear-gradient(90deg, rgba(30,136,229,1) 0%, rgba(21,101,192,1) 100%)",
              },
            }}
          >
            Test Agent
          </Button>
        </div>
      ) : null}

      <Typography
        variant="h4"
        gutterBottom
        align="center"
        sx={{ fontWeight: "bold", marginTop: "40px" }}
      >
        Simple, Transparent Pricing
      </Typography>
      <Typography
        variant="subtitle1"
        gutterBottom
        align="center"
        sx={{ marginBottom: "40px" }}
      >
        We believe Bolna should be accessible to all companies, no matter the
        size.
      </Typography>

      {/* Pricing Tiers */}
      <Grid container spacing={3} justifyContent="center">
        {tiers.map((tier, index) => (
          <Grid item xs={12} sm={6} md={4} key={tier.name}>
            <Card
              variant="outlined"
              sx={{
                height: "100%",
                display: "flex",
                backgroundColor:
                  user_data.plan_name == tier.name ? "#C4D7FF" : "#ffffff",
                flexDirection: "column",
                justifyContent: "space-between",
                borderRadius: "10px",
              }}
            >
              <CardContent>
                <Typography
                  variant="h5"
                  sx={{ fontWeight: "bold" }}
                  gutterBottom
                >
                  {tier.name}
                </Typography>
                <Typography
                  variant="h4"
                  sx={{ fontWeight: "bold", marginBottom: "20px" }}
                >
                  ${tier.price}/mth
                </Typography>
                <ul>
                  {tier.features.map((feature, index) => (
                    <Typography
                      component="li"
                      key={index}
                      variant="body1"
                      sx={{ marginBottom: "10px" }}
                    >
                      {feature}
                    </Typography>
                  ))}
                </ul>
              </CardContent>
              {user_data.plan_name === tier.name ? (
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ margin: "20px" }}
                  onClick={() => onSelect(tier)}
                  disabled
                >
                  Active
                </Button>
              ) : user_data.plan_name ? (
                index > currentIndex ? (
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ margin: "20px" }}
                    onClick={() => onSelect(tier)}
                  >
                    Upgrade
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ margin: "20px" }}
                    onClick={() => onSelect(tier)}
                  >
                    Downgrade
                  </Button>
                )
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ margin: "20px" }}
                  onClick={() => onSelect(tier)}
                >
                  Get started
                </Button>
              )}
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* Dialog for Test Agent */}
      <Dialog
        open={openDialog}
        onClose={handleClose}
        PaperProps={{
          sx: {
            padding: "20px",
            borderRadius: "15px", // Make the dialog box rounded
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", // Subtle shadow
          },
        }}
      >
        <DialogTitle
          sx={{ textAlign: "center", fontWeight: "bold", fontSize: "20px" }}
        >
          Enter Test Agent Number
        </DialogTitle>
        <DialogContent>
          <Typography
            variant="caption"
            gutterBottom
            align="center"
            sx={{ marginBottom: "40px" }}
          >
            Enter phone numbers with country code (for example: +16507638870)
          </Typography>
          <InputLabel
            sx={{ fontWeight: "bold", marginBottom: "10px", fontSize: "14px" }}
          >
            Phone Number
          </InputLabel>
          <TextField
            sx={{
              marginTop: "10px",
              marginBottom: "20px",
              borderRadius: "8px",
              "& .MuiOutlinedInput-root": {
                borderRadius: "10px",
              },
            }}
            autoFocus
            margin="dense"
            label="Mobile Number"
            type="text"
            fullWidth
            value={testNumber}
            onChange={handleTestNumberChange}
            variant="outlined"
          />
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center" }}>
          <Button
            onClick={handleClose}
            color="primary"
            variant="outlined"
            sx={{
              fontSize: "14px",
              fontWeight: "bold",
              padding: "8px 20px",
              borderRadius: "30px",
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleDialogSubmit}
            color="primary"
            variant="contained"
            sx={{
              fontSize: "14px",
              fontWeight: "bold",
              padding: "8px 20px",
              borderRadius: "30px",
              background:
                "linear-gradient(90deg, rgba(33,150,243,1) 0%, rgba(30,136,229,1) 100%)",
              "&:hover": {
                background:
                  "linear-gradient(90deg, rgba(30,136,229,1) 0%, rgba(21,101,192,1) 100%)",
              },
            }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default TierSelection;
