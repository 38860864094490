import React from "react";
import { Card, CardContent, Typography, styled, Box, Button, CardMedia } from "@mui/material";
import { keyframes } from "@emotion/react";

// Animation for pop-out effect
const popOutAnimation = keyframes`
  0% {
    transform: scale(0.8);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

const StyledCard = styled(Card)(({ cardColor }) => ({
  borderRadius: "16px", // Curved borders for smooth edges
  backgroundColor: cardColor, // Dynamically use cardColor prop
  boxShadow: "0 8px 20px rgba(0, 0, 0, 0.15)", // Soft shadow for more elegance
  animation: `${popOutAnimation} 0.5s ease-out`,
  transition: "transform 0.3s ease, box-shadow 0.3s ease", // Smooth transition
  "&:hover": {
    transform: "scale(1.05)", // Slight zoom on hover
    boxShadow: "0 12px 30px rgba(0, 0, 0, 0.3)", // Stronger shadow on hover
  },
}));

const AgentCard = ({ color, name, activeText,activeButton,agent,onActivate }) => {
  const handleActivate = (ele)=>{
    // console.log(ele)
    onActivate(agent)
  }
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // padding: "20px", // Adds spacing around the card
        paddingBottom:"10px"
      }}
    >
      <StyledCard cardColor={color}>
        <CardContent
          sx={{
            padding: "24px", // Adds some padding inside the card
          }}
        >
          <Typography
            variant="h5"
            component="div"
            sx={{
              fontWeight: "bold", // Bold for emphasis
              color: "#000", // White text for contrast
              marginBottom: "12px", // Space below the title
            }}
          >
            {name}
          </Typography>
          <CardMedia
              component="img"
              height="220"
              image="https://www.svgrepo.com/show/10123/call-center-operator.svg"
              alt="Yosemite"
              />
          <Typography
            variant="caption"
            sx={{
              color: "#000", // Softer white for the secondary text
            }}
          >
            {name.includes("Front")? (<>It is an intelligent virtual assistant designed to manage customer interactions, handle inquiries, enhancing operational efficiency. It provides 24/7 support.</>):(<>It assists clients by addressing inquiries, resolving issues, and providing product or service information. It ensures customer satisfaction through effective communication and problem-solving.</>)}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              color: "#000", // Softer white for the secondary text
            }}
          >
            status : <strong>{activeText}</strong>
          </Typography>
          {activeButton ? (
                <Button
                  onClick={()=>{handleActivate(agent)}}
                  variant="contained"
                  size="small"
                  sx={{
                    marginTop: "8px",
                    backgroundColor: "#1976d2",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "#1565c0",
                    },
                  }}
                >
                  Activate Agent
                </Button>
              ) : null}
        </CardContent>
      </StyledCard>
    </Box>
  );
};

export default AgentCard;
