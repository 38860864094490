import React, { forwardRef } from "react";
import { Typography, Grid, Box, Button } from "@mui/material";
import AgentCard from "../component/agent-cards";
import { getRandomColor } from "../utils/colorGenerator";
import { useNavigate } from "react-router-dom";


const AgentChoose = forwardRef(
  ({ onAgentSelect, agentData, activeAgent, onActivate }, ref) => {
    const navigate = useNavigate();

    const generateRandomColors = (count) => {
      return Array.from({ length: count }, getRandomColor);
    };


    return (
      <Box
        ref={ref}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "80%",
          marginX: "auto",
          marginTop: "4%",
          padding: "20px", // Added padding for overall better look
        }}
      >
        <Typography
          variant="h3"
          component="h1"
          sx={{
            marginBottom: "2%",
            fontWeight: "bold",
            color: "#1976d2", // Use theme primary color or custom color
          }}
        >
          Choose Your Agent
        </Typography>

        <Grid container spacing={4} justifyContent="center">
          {agentData.map((ele, idx) => (
            <Grid item xs={12} sm={6} md={4} key={ele.id}>
              {/* Removed Paper, Direct Card Placement */}
              <div onClick={() => onAgentSelect(ele)}>
                <AgentCard
                  name={ele.agent_name}
                  agent={ele}
                  color={activeAgent.agent_id === ele.id ? "#42a5f5" : "#F4F6FF"}
                  activeText={activeAgent.agent_id === ele.id ? "Active" : "Inactive"}
                  activeButton = {activeAgent.agent_id !== ele.id && Object.keys(activeAgent).length > 0 ? true:false}
                  onActivate={onActivate}
                />
              </div>

              {/* {activeAgent.agent_id !== ele.id && Object.keys(activeAgent).length > 0 ? (
                <Button
                  onClick={() => onActivate(ele)}
                  variant="contained"
                  size="small"
                  sx={{
                    marginTop: "8px",
                    backgroundColor: "#1976d2",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "#1565c0",
                    },
                  }}
                >
                  Activate Agent
                </Button>
              ) : null} */}
            </Grid>
          ))}
        </Grid>

        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate("/dataroom")}
          sx={{
            marginTop: "20px",
            padding: "10px 20px",
            fontWeight: "bold",
            fontSize: "1rem",
            "&:hover": {
              backgroundColor: "#1565c0",
            },
          }}
        >
          Go to Dataroom
        </Button>
      </Box>
    );
  }
);

export default AgentChoose;
