import React, { useState } from "react";
import { Container, Box, TextField, Typography, Button } from "@mui/material";
import VoiceCards from "../component/VoiceCards";

const AgentPersonalisation = ({
  onSubmit,
  onPrev,
  text,
  apiKey,
  data,
  voiceId,
}) => {
  // State hooks for storing input values
  const [businessDescription, setBusinessDescription] = useState(
    data.businessDescription || ""
  );
  const [productsDescription, setProductsDescription] = useState(
    data.productDescription || ""
  );
  const [companyFaqs, setCompanyFaqs] = useState(data.companyFaq || "");
  const [selectedVoice, setSelectedVoice] = useState({
    name: "Sheps Rocky",
    id: "d5xU2Rwln0n15oHMmaTU",
    languageCode: "all",
    model: "eleven_turbo_v2_5",
    provider: "elevenlabs",
    lowLatency: false,
    accent: "american",
  });
  const handleSubmit = () => {
    console.log("Business Description:", businessDescription);
    console.log("Products Description:", productsDescription);
    console.log("Company FAQ's:", companyFaqs);
    console.log("selected Voice", selectedVoice);

    onSubmit({
      businessDescription,
      productsDescription,
      companyFaqs,
      selectedVoice,
    });
    // Optionally clear the form fields
    // setBusinessDescription("");
    // setProductsDescription("");
    // setCompanyFaqs("");
  };

  const handleVoice = (value) => {
    console.log(value);
    setSelectedVoice(value);
  };
  const handlePrev = () => {
    onPrev();
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" }, // Column on small screens, row on medium and up
          gap: 4, // Gap between columns
          backgroundColor: "white",
          padding: 3,
          borderRadius: 2,
          boxShadow: 3,
          mb: 2,
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            flex: 1, // Takes up available space
          }}
        >
          <Typography variant="h6" gutterBottom>
            Agent Personalisation
          </Typography>

          <Box mb={3}>
            <Typography variant="subtitle1" gutterBottom>
              Business Description
            </Typography>
            <TextField
              fullWidth
              multiline
              rows={3}
              variant="outlined"
              placeholder="Enter business description here..."
              value={businessDescription}
              onChange={(e) => setBusinessDescription(e.target.value)}
            />
          </Box>

          <Box mb={3}>
            <Typography variant="subtitle1" gutterBottom>
              Products Description
            </Typography>
            <TextField
              fullWidth
              multiline
              rows={3}
              variant="outlined"
              placeholder="Enter products description here..."
              value={productsDescription}
              onChange={(e) => setProductsDescription(e.target.value)}
            />
          </Box>

          <Box mb={3}>
            <Typography variant="subtitle1" gutterBottom>
              Company FAQ's
            </Typography>
            <TextField
              fullWidth
              multiline
              rows={3}
              variant="outlined"
              placeholder="Enter company FAQ's here..."
              value={companyFaqs}
              onChange={(e) => setCompanyFaqs(e.target.value)}
            />
          </Box>
          <Button
            variant="contained"
            color="primary"
            onClick={handlePrev}
            sx={{ alignSelf: "flex-end", marginRight: "5px" }} // Align the button to the end of the container
          >
            Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            sx={{ alignSelf: "flex-end" }} // Align the button to the end of the container
          >
            Next
          </Button>
        </Box>

        <Box
          sx={{
            gap: 1, // Gap between voice cards and button
          }}
        >
          <Typography variant="h6" gutterBottom>
            Voice Cards
          </Typography>
          <VoiceCards
            onSelect={handleVoice}
            text={text}
            apiKey={apiKey}
            voice={selectedVoice}
            voiceId={voiceId}
          />
        </Box>
      </Box>
    </Container>
  );
};

export default AgentPersonalisation;
