import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Typography,
  InputLabel,
} from "@mui/material";

const AmountDialog = ({ openDialog, handleClose, onSubmit, plan_name }) => {
  const [amount, setAmount] = useState("");

  const convertDollarsToMinutes = (amountInDollars) => {
    const costPerMinute = plan_name == "Premium" ? 8 : 10;
    const amountInCents = amountInDollars * 100;
    const minutes = amountInCents / costPerMinute;
    return minutes;
  };

  const handleAmountChange = (event) => {
    setAmount(event.target.value);
  };

  const handlePresetClick = (value) => {
    setAmount(value);
  };

  const handleDialogSubmit = () => {
    // Handle the submit action
    console.log(`Amount in cents: ${amount}`);
    console.log(`Amount in dollars: ${(amount / 100).toFixed(2)}`);
    onSubmit({ minutes: convertDollarsToMinutes(amount), amount });
    handleClose();
  };

  return (
    <Dialog
      open={openDialog}
      onClose={handleClose}
      PaperProps={{
        sx: {
          padding: "20px",
          borderRadius: "15px",
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
        },
      }}
    >
      <DialogTitle
        sx={{ textAlign: "center", fontWeight: "bold", fontSize: "20px" }}
      >
        Enter Amount in Dollars
      </DialogTitle>
      <DialogContent>
        <Typography
          variant="caption"
          gutterBottom
          align="center"
          sx={{ marginBottom: "40px" }}
        >
          Enter the amount in dollars or choose a preset value.
        </Typography>
        <InputLabel
          sx={{ fontWeight: "bold", marginBottom: "10px", fontSize: "14px" }}
        >
          Amount (Dollars)
        </InputLabel>
        <TextField
          sx={{
            marginTop: "10px",
            marginBottom: "20px",
            borderRadius: "8px",
            "& .MuiOutlinedInput-root": {
              borderRadius: "10px",
            },
          }}
          autoFocus
          margin="dense"
          label="Amount in Dollars"
          type="number"
          fullWidth
          value={amount}
          onChange={handleAmountChange}
          variant="outlined"
        />
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            marginBottom: "20px",
          }}
        >
          {[2, 5, 10, 50].map((value) => (
            <Button
              key={value}
              variant="outlined"
              onClick={() => handlePresetClick(value)}
              sx={{ borderRadius: "30px", padding: "8px 16px" }}
            >
              {value}$
            </Button>
          ))}
        </div>
        <Typography variant="body2" align="center">
          Total minutes: {convertDollarsToMinutes(amount)}
        </Typography>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <Button
          onClick={handleClose}
          color="primary"
          variant="outlined"
          sx={{
            fontSize: "14px",
            fontWeight: "bold",
            padding: "8px 20px",
            borderRadius: "30px",
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleDialogSubmit}
          color="primary"
          variant="contained"
          sx={{
            fontSize: "14px",
            fontWeight: "bold",
            padding: "8px 20px",
            borderRadius: "30px",
            background:
              "linear-gradient(90deg, rgba(33,150,243,1) 0%, rgba(30,136,229,1) 100%)",
            "&:hover": {
              background:
                "linear-gradient(90deg, rgba(30,136,229,1) 0%, rgba(21,101,192,1) 100%)",
            },
          }}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AmountDialog;
